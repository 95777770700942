.preview {
  display: none;
  @media (min-width: 992px) {
    display: block;
    background: radial-gradient(circle, #FFFFFF 0%, #BAB2FF 100%);
    width: 560px;
    box-shadow: 0 11px 25px 4px rgba(73,73,73,0.5);
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    &__bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 11px;
      box-shadow: 0 1px 2px 0 rgba(158,158,158,0.5);
      background: linear-gradient(0deg, #8D80F8 0%, #C3BCFE 100%);
      border-radius: 15px 15px 0 0;
      height: 35px;
    }
    &__messages {
      padding: 0 15px;
    }
    &__clipped {
      height: 23px;
      background: white;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 3px 3px 1px rgba(210,210,210,0.5);
      margin-bottom: 10px;
    }
    &__message {
      padding: 20px;
      background: white;
      border-radius: 5px;
      box-shadow: 0 3px 3px 1px rgba(210,210,210,0.5);
      background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: -14px;
      }
    }
    &__bot {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__user {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 11px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__avatar {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      background: #E9E7FF;
      margin-right: 8px;
    }
    &__username {
      font-family: 'Signika';
      font-size: 14px;
      margin-right: 10px;
    }
    &__link {
      color: #8677FF;
      font-weight: 600;
    }
    &__label {
      font-family: 'Lato';
      font-size: 9px;
      background: #D8D8D8;
      border-radius: 2px;
      padding: 1px 4px;
      margin-right: 7px;
    }
    &__timestamp {
      color: #9B9B9B;
      font-size: 12px;
      font-weight: 600;
    }
    &__body {
      padding: 10px 36px;
      font-size: 14px;
      line-height: 27px;
    }
    &__text {
      margin-bottom: 14px;
    }
    &__users {
      border-left: solid 4px #FFB800;
      padding: 4px 0 4px 18px;
      margin-bottom: 14px;
    }
    &__photo {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background: #E9E7FF;
      margin-right: 16px;
    }
    &__hours {
      font-weight: 600;
      font-size: 13px;
    }
    &__emoji {
      margin-left: 10px;
    }
    &__button {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-right: 5px;
      &:nth-child(1) {
        background: #D0021B;
      }
      &:nth-child(2) {
        background: #FFB800;
      }
      &:nth-child(3) {
        background: #7ED321;
      }
    }
  }
}