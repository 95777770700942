.header {
  padding: 0 0 180px 0;
  &__actions {
    display: flex;
    justify-content: center;
  }
  &__aside {
    @media (min-width: 992px) {
      width: 455px;
    }
  }
  &__headline {
    line-height: 42px;
    font-family: 'Signika', sans-serif;
    text-align: center;
    font-size: 24px;
    margin-bottom: 5px;
    font-weight: 900;
    color: white;
  }
  &__strapline {
    color: white;
    font-size: 16px;
    text-align: center;
    line-height: 28px;
    margin-bottom: 50px;
  }
  @media (min-width: 992px) {
    &__actions {
      justify-content: flex-start;
    }
    &__headline {
      font-size: 40px;
      line-height: 50px;
      text-align: left;
    }
    &__strapline {
      font-size: 24px;
      line-height: 35px;
      text-align: left;
    }
  }
}