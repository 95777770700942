@import url(https://fonts.googleapis.com/css?family=Hind:600|Lato:300,400|Signika:600);
@import url(https://use.fontawesome.com/releases/v5.7.1/css/all.css);
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f4f4; }

.choice {
  padding: 8px 12px;
  color: #665CB8;
  background: -webkit-linear-gradient(top, #f6f5fd 0%, #efedff 100%);
  background: linear-gradient(180deg, #f6f5fd 0%, #efedff 100%);
  border: 0.5px solid rgba(138, 125, 255, 0.4);
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 3px; }

select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: -webkit-linear-gradient(top, #f6f5fd 0%, #efedff 100%);
  background: linear-gradient(180deg, #f6f5fd 0%, #efedff 100%);
  border: 0.5px solid rgba(138, 125, 255, 0.4);
  border-radius: 5px;
  width: 100%;
  padding: 8px 12px;
  color: #665CB8;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  display: block; }

a {
  text-decoration: none; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.wrapper {
  padding: 0 20px; }

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 10px; }

.row {
  display: flex;
  flex-direction: column;
  margin: 0 -10px 50px -10px; }

.column {
  padding: 0 10px;
  flex: 1 1; }

.enemies {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center; }

.center {
  text-align: center; }

@media (min-width: 992px) {
  .row {
    flex-direction: row; } }

.button {
  color: black;
  padding: 9px 18px 6px 18px;
  font-size: 16px;
  line-height: 26px;
  border-radius: 5px;
  background: -webkit-linear-gradient(94deg, #FFDD23 0%, #FCEF08 100%);
  background: linear-gradient(356deg, #FFDD23 0%, #FCEF08 100%);
  font-family: 'Hind', sans-serif;
  display: inline-block;
  margin-right: 10px;
  border: 0; }
  .button:hover {
    background: -webkit-linear-gradient(98.84deg, #FFCB23 0%, #FCEF08 100%);
    background: linear-gradient(351.16deg, #FFCB23 0%, #FCEF08 100%); }
  .button:last-child {
    margin-right: 0; }
  .button--outline {
    background: rgba(252, 239, 8, 0.1);
    border: 2px solid #FCEF08;
    color: #FCEF08;
    box-sizing: border-box; }
    .button--outline:hover {
      background: rgba(252, 239, 8, 0.4); }
  .button--secondary {
    background: transparent;
    color: white; }
    .button--secondary:hover {
      text-decoration: underline;
      background: transparent; }
  .button--large {
    padding: 14px 46px 11px 46px;
    font-size: 24px;
    border-radius: 10px; }
  .button--shadow {
    box-shadow: 0 2px 3px 0 rgba(176, 176, 176, 0.5); }

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F4F3FF;
  text-transform: uppercase; }

.greeting__title {
  margin-bottom: 20px;
  font-weight: 500; }

.greeting__text {
  font-size: 15px;
  line-height: 26px;
  color: #4A4A4A; }

.greeting__tip {
  text-align: right;
  font-size: 12px;
  margin-bottom: 40px; }

@media (min-width: 992px) {
  .greeting__text {
    padding: 10px 50px; } }

.preview {
  display: none; }
  @media (min-width: 992px) {
    .preview {
      display: block;
      background: -webkit-radial-gradient(circle, #FFFFFF 0%, #BAB2FF 100%);
      background: radial-gradient(circle, #FFFFFF 0%, #BAB2FF 100%);
      width: 560px;
      box-shadow: 0 11px 25px 4px rgba(73, 73, 73, 0.5);
      border-radius: 15px 15px 0 0;
      overflow: hidden; }
      .preview__bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 11px;
        box-shadow: 0 1px 2px 0 rgba(158, 158, 158, 0.5);
        background: -webkit-linear-gradient(bottom, #8D80F8 0%, #C3BCFE 100%);
        background: linear-gradient(0deg, #8D80F8 0%, #C3BCFE 100%);
        border-radius: 15px 15px 0 0;
        height: 35px; }
      .preview__messages {
        padding: 0 15px; }
      .preview__clipped {
        height: 23px;
        background: white;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 3px 1px rgba(210, 210, 210, 0.5);
        margin-bottom: 10px; }
      .preview__message {
        padding: 20px;
        background: white;
        border-radius: 5px;
        box-shadow: 0 3px 3px 1px rgba(210, 210, 210, 0.5);
        background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 100%);
        background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
        margin-bottom: 10px; }
        .preview__message:last-child {
          margin-bottom: -14px; }
      .preview__bot {
        display: flex;
        flex-direction: row;
        align-items: center; }
      .preview__user {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 11px; }
        .preview__user:last-child {
          margin-bottom: 0; }
      .preview__avatar {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        background: #E9E7FF;
        margin-right: 8px; }
      .preview__username {
        font-family: 'Signika';
        font-size: 14px;
        margin-right: 10px; }
      .preview__link {
        color: #8677FF;
        font-weight: 600; }
      .preview__label {
        font-family: 'Lato';
        font-size: 9px;
        background: #D8D8D8;
        border-radius: 2px;
        padding: 1px 4px;
        margin-right: 7px; }
      .preview__timestamp {
        color: #9B9B9B;
        font-size: 12px;
        font-weight: 600; }
      .preview__body {
        padding: 10px 36px;
        font-size: 14px;
        line-height: 27px; }
      .preview__text {
        margin-bottom: 14px; }
      .preview__users {
        border-left: solid 4px #FFB800;
        padding: 4px 0 4px 18px;
        margin-bottom: 14px; }
      .preview__photo {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #E9E7FF;
        margin-right: 16px; }
      .preview__hours {
        font-weight: 600;
        font-size: 13px; }
      .preview__emoji {
        margin-left: 10px; }
      .preview__button {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin-right: 5px; }
        .preview__button:nth-child(1) {
          background: #D0021B; }
        .preview__button:nth-child(2) {
          background: #FFB800; }
        .preview__button:nth-child(3) {
          background: #7ED321; } }

.navigation {
  padding: 60px 0; }
  .navigation__logo {
    height: 44px;
    width: 44px;
    border-radius: 5px;
    text-align: center;
    line-height: 44px;
    font-size: 32px; }
    .navigation__logo img {
      border-radius: 5px; }
  .navigation__logout {
    cursor: pointer;
    font-family: 'Hind', sans-serif;
    font-size: 18px; }

.background {
  background: -webkit-repeating-radial-gradient(bottom, circle, #9890FF 20px, #9387FF 250px);
  background: repeating-radial-gradient(circle at bottom, #9890FF 20px, #9387FF 250px);
  position: relative;
  z-index: 5;
  overflow: hidden; }

.privacy-policy .panel p {
  text-align: center;
  font-size: 24px;
  line-height: 35px;
  font-weight: lighter; }

.privacy-policy__headline {
  color: #8677FF;
  font-family: 'Signika', sans-serif;
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 6px; }

.privacy-policy__strapline {
  font-size: 24px;
  line-height: 35px;
  font-weight: 500;
  margin-bottom: 60px; }

.privacy-policy__title {
  color: #8677FF;
  font-family: 'Signika', sans-serif;
  font-size: 26px;
  line-height: 26px;
  margin: 0 0 24px 0; }

.privacy-policy__text, .privacy-policy__list {
  font-size: 15px;
  line-height: 26px;
  margin: 0 0 20px 0; }

.privacy-policy__list {
  margin: 0 0 20px 20px; }

.privacy-policy__heading {
  font-family: 'Signika', sans-serif;
  font-size: 20px;
  margin: 0 0 10px 0;
  color: #8677FF; }

.privacy-policy__description {
  font-size: 24px;
  line-height: 35px;
  font-weight: lighter;
  margin: 0 0 60px 0; }

.configure {
  max-width: 760px;
  padding: 60px 0;
  margin: 0 auto; }
  .configure__headline {
    color: #8677FF;
    font-family: 'Signika', sans-serif;
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 6px;
    text-align: center; }
  .configure__strapline {
    font-size: 24px;
    line-height: 35px;
    font-weight: 500;
    margin-bottom: 60px;
    text-align: center; }
  .configure__cta {
    border: 1px solid #A9A0FF;
    border-radius: 5px;
    padding: 9px 14px;
    font-family: 'Signika', sans-serif;
    font-weight: 600;
    color: black;
    display: flex;
    align-items: center; }

.header {
  padding: 0 0 180px 0; }
  .header__actions {
    display: flex;
    justify-content: center; }
  @media (min-width: 992px) {
    .header__aside {
      width: 455px; } }
  .header__headline {
    line-height: 42px;
    font-family: 'Signika', sans-serif;
    text-align: center;
    font-size: 24px;
    margin-bottom: 5px;
    font-weight: 900;
    color: white; }
  .header__strapline {
    color: white;
    font-size: 16px;
    text-align: center;
    line-height: 28px;
    margin-bottom: 50px; }
  @media (min-width: 992px) {
    .header__actions {
      justify-content: flex-start; }
    .header__headline {
      font-size: 40px;
      line-height: 50px;
      text-align: left; }
    .header__strapline {
      font-size: 24px;
      line-height: 35px;
      text-align: left; } }

.features {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 0 50px 0; }
  @media (min-width: 992px) {
    .features {
      width: 930px; } }

.feature {
  display: flex;
  flex-direction: row;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  background: -webkit-linear-gradient(top, #F3F3F3 0%, #FFFFFF 100%);
  background: linear-gradient(180deg, #F3F3F3 0%, #FFFFFF 100%);
  box-shadow: 0 11px 5px -6px rgba(207, 207, 207, 0.5);
  margin: 10px;
  box-sizing: border-box;
  padding: 30px 22px; }
  .feature__title {
    color: #8677FF;
    line-height: 42px;
    font-family: 'Signika', sans-serif;
    margin-bottom: 5px;
    font-size: 16px; }
  .feature__description {
    font-size: 15px;
    line-height: 26px; }
  .feature__emoji {
    font-size: 40px;
    margin-right: 22px;
    padding-top: 30px; }
  @media (min-width: 992px) {
    .feature {
      height: 170px;
      width: 445px; }
      .feature__title {
        font-size: 20px;
        line-height: 26px; } }

.footer {
  background-color: #665CB8;
  height: 180px;
  padding-top: 40px; }
  .footer__links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0; }
  .footer__link {
    margin-right: 20px; }
    .footer__link:last-child {
      margin-right: 0; }
  .footer__navigation {
    color: white;
    font-size: 15px; }
  .footer__copy, .footer__email {
    opacity: 0.5;
    color: white;
    font-size: 12px;
    padding: 10px 0;
    text-align: center; }
    .footer__copy a, .footer__email a {
      color: white; }
  @media (min-width: 992px) {
    .footer__enemies {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
    .footer__links {
      justify-content: flex-start; }
    .footer__copy, .footer__email {
      text-align: left; } }

.intro {
  padding: 160px 0;
  margin: 0 auto; }
  .intro__headline {
    color: #8677FF;
    text-align: center;
    line-height: 36px;
    font-size: 24px;
    font-family: 'Signika', sans-serif;
    font-weight: 900;
    margin-bottom: 30px; }
  .intro__strapline {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    margin-bottom: 60px; }
  @media (min-width: 992px) {
    .intro__headline {
      font-size: 40px;
      line-height: 45px;
      width: 570px;
      margin-right: auto;
      margin-left: auto; }
    .intro__strapline {
      font-size: 24px;
      line-height: 35px;
      width: 760px;
      margin-right: auto;
      margin-left: auto; } }

.panel {
  background-color: white;
  padding: 35px 25px;
  border-radius: 5px;
  border: 2px solid rgba(138, 125, 255, 0.4);
  margin-bottom: 20px; }
  .panel__title {
    color: #8677FF;
    line-height: 26px;
    font-size: 20px;
    margin-bottom: 7px;
    font-family: 'Signika', sans-serif;
    font-weight: 600; }
  .panel__description {
    font-size: 15px;
    line-height: 26px;
    min-height: 80px;
    font-weight: 400;
    color: #4A4A4A; }
  .panel__disabled {
    opacity: 0.3; }

.settings__headline {
  color: #8677FF;
  font-family: 'Signika', sans-serif;
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 6px; }

.settings__strapline {
  font-size: 24px;
  line-height: 35px;
  font-weight: 500;
  margin-bottom: 60px; }

.settings__actions {
  display: none;
  background-color: #BEB8FF;
  padding: 25px 0; }

