@import url('https://fonts.googleapis.com/css?family=Hind:600|Lato:300,400|Signika:600');
@import url('https://use.fontawesome.com/releases/v5.7.1/css/all.css');

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f4f4;
}

.choice {
  padding: 8px 12px;
  color: #665CB8;
  background: linear-gradient(180deg, #f6f5fd 0%, #efedff 100%);;
  border: 0.5px solid rgba(138,125,255,0.4);
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 3px;
}

select {
  appearance: none;
  background: linear-gradient(180deg, #f6f5fd 0%, #efedff 100%);;
  border: 0.5px solid rgba(138,125,255,0.4);
  border-radius: 5px;
  width: 100%;
  padding: 8px 12px;
  color: #665CB8;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  display: block;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@import './layout';
@import './components/button';
@import './components/loading';
@import './components/greeting';
@import './components/preview';
@import './components/navigation';
@import './components/background';
@import './components/privacy-policy';
@import './components/configure';
@import './components/header';
@import './components/features';
@import './components/feature';
@import './components/footer';
@import './components/intro';
@import './components/panel';
@import './components/settings';
