.privacy-policy {
  .panel {
    p {
      text-align: center;
      font-size: 24px;
      line-height: 35px;
      font-weight: lighter;
    }
  }
  &__headline {
    color: #8677FF;
    font-family: 'Signika', sans-serif;
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 6px;
  }
  &__strapline {
    font-size: 24px;
    line-height: 35px;
    font-weight: 500;
    margin-bottom: 60px;
  }
  &__title {
    color: #8677FF;
    font-family: 'Signika', sans-serif;
    font-size: 26px;
    line-height: 26px;
    margin: 0 0 24px 0;
  }
  &__text,
  &__list {
    font-size: 15px;
    line-height: 26px;
    margin: 0 0 20px 0;
  }
  &__list {
    margin: 0 0 20px 20px;
  }
  &__heading {
    font-family: 'Signika', sans-serif;
    font-size: 20px;
    margin: 0 0 10px 0;
    color: #8677FF;
  }
  &__description {
    font-size: 24px;
    line-height: 35px;
    font-weight: lighter;
    margin: 0 0 60px 0;
  }
}