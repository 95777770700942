.panel {
  background-color: white;
  padding: 35px 25px;
  border-radius: 5px;
  border: 2px solid rgba(138,125,255,0.4);
  margin-bottom: 20px;
  &__title {
    color: #8677FF;
    line-height: 26px;
    font-size: 20px;
    margin-bottom: 7px;
    font-family: 'Signika', sans-serif;
    font-weight: 600;
  }
  &__description {
    font-size: 15px;
    line-height: 26px;
    // margin-bottom: 40px;
    min-height: 80px;
    font-weight: 400;
    color: #4A4A4A;
  }
  &__disabled {
    opacity: 0.3;
  }
}