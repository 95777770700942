.settings {
  &__headline {
    color: #8677FF;
    font-family: 'Signika', sans-serif;
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 6px;
  }
  &__strapline {
    font-size: 24px;
    line-height: 35px;
    font-weight: 500;
    margin-bottom: 60px;
  }
  &__actions {
    display: none;
    background-color: #BEB8FF;
    padding: 25px 0;
  }
}