.greeting {
  &__title {
    margin-bottom: 20px;
    font-weight: 500;
  }
  &__text {
    font-size: 15px;
    line-height: 26px;
    color: #4A4A4A;
  }
  &__tip {
    text-align: right;
    font-size: 12px;
    margin-bottom: 40px
  }
  @media (min-width: 992px) {
    &__text {
      padding: 10px 50px;
    }
  }
}