.button {
  color: black;
  padding: 9px 18px 6px 18px;
  font-size: 16px;
  line-height: 26px;
  border-radius: 5px;
  background: linear-gradient(356deg, #FFDD23 0%, #FCEF08 100%);
  font-family: 'Hind', sans-serif;
  display: inline-block;
  margin-right: 10px;
  border: 0;
  &:hover {
    background: linear-gradient(351.16deg, #FFCB23 0%, #FCEF08 100%);
  }
  &:last-child {
    margin-right: 0;
  }
  &--outline {
    background: rgba(252,239,8,0.1);
    border: 2px solid #FCEF08;
    color: #FCEF08;
    box-sizing: border-box;
    &:hover {
      background: rgba(252,239,8,0.4);
    }
  }
  &--secondary {
    background: transparent;
    color: white;
    &:hover {
      text-decoration: underline;
      background: transparent;
    }
  }
  &--large {
    padding: 14px 46px 11px 46px;
    font-size: 24px;
    border-radius: 10px;
  }
  &--shadow {
    box-shadow: 0 2px 3px 0 rgba(176,176,176,0.5);
  }
}
