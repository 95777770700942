.background {
  background: repeating-radial-gradient(circle at bottom, #9890FF 20px, #9387FF 250px);
  position: relative;
  z-index: 5;
  overflow: hidden;

  // background: url('../clip.svg') bottom, url('../gradient.svg');
  // background-size: cover, cover;
  // background-size: cover;
  // &__clip {
  //   background-image: url('../clip.svg');
  //   background-size: cover;
  //   height: 100px;
  // }
}