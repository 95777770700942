.intro {
  padding: 160px 0;
  margin: 0 auto;
  @media (min-width: 992px) {
    // width: 760px;
  }
  &__headline {
    color: #8677FF;
    text-align: center;
    line-height: 36px;
    font-size: 24px;
    font-family: 'Signika', sans-serif;
    font-weight: 900;
    margin-bottom: 30px;
  }
  &__strapline {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    margin-bottom: 60px;
  }
  @media (min-width: 992px) {
    &__headline {
      font-size: 40px;
      line-height: 45px;
      width: 570px;
      margin-right: auto;
      margin-left: auto;
    }
    &__strapline {
      font-size: 24px;
      line-height: 35px;
      width: 760px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}