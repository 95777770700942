.configure {
  max-width: 760px;
  padding: 60px 0;
  margin: 0 auto;
  &__headline {
    color: #8677FF;
    font-family: 'Signika', sans-serif;
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 6px;
    text-align: center;
  }
  &__strapline {
    font-size: 24px;
    line-height: 35px;
    font-weight: 500;
    margin-bottom: 60px;
    text-align: center;
  }
  &__cta {
    border: 1px solid #A9A0FF;
    border-radius: 5px;
    padding: 9px 14px;
    font-family: 'Signika', sans-serif;
    font-weight: 600;
    color: black;
    display: flex;
    align-items: center;
  }
}