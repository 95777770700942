.footer {
  background-color: #665CB8;
  height: 180px;
  padding-top: 40px;
  &__links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0;
  }
  &__link {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__navigation {
    color: white;
    font-size: 15px;
  }
  &__copy,
  &__email {
    opacity: 0.5;
    color: white;
    font-size: 12px;
    padding: 10px 0;
    text-align: center;
    a {
      color: white;
    }
  }
  @media (min-width: 992px) {
    &__enemies {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    &__links {
      justify-content: flex-start;
    }
    &__copy,
    &__email {
      text-align: left;
    }
  }
}