.navigation {
  padding: 60px 0;
  &__logo {
    height: 44px;
    width: 44px;
    border-radius: 5px;
    // border: 1px solid #979797;
    // background-color: #D8D8D8;
    text-align: center;
    line-height: 44px;
    font-size: 32px;
    img {
      border-radius: 5px;
    }
  }
  &__logout {
    cursor: pointer;
    font-family: 'Hind', sans-serif;
    font-size: 18px;
  }
}