.feature {
  display: flex;
  flex-direction: row;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  background: linear-gradient(180deg, #F3F3F3 0%, #FFFFFF 100%);
  box-shadow: 0 11px 5px -6px rgba(207,207,207,0.5);
  margin: 10px;
  box-sizing: border-box;
  padding: 30px 22px;
  &__title {
    color: #8677FF;
    line-height: 42px;
    font-family: 'Signika', sans-serif;
    margin-bottom: 5px;
    font-size: 16px;
  }
  &__description {
    font-size: 15px;
    line-height: 26px;
  }
  &__emoji {
    font-size: 40px;
    margin-right: 22px;
    padding-top: 30px;
  }
  @media (min-width: 992px) {
    height: 170px;
    width: 445px;
    &__title {
      font-size: 20px;
      line-height: 26px;
    }
  }
}