.wrapper {
  padding: 0 20px;
}

.container {
  max-width: 1140px; // 120px cols
  margin: 0 auto;
  padding: 0 10px;
}

.row {
  display: flex;
  flex-direction: column;
  margin: 0 -10px 50px -10px;
}

.column {
  padding: 0 10px;
  flex: 1 1 0;
}

.enemies {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.center {
  text-align: center;
}

@media (min-width: 992px) {
  .row {
    flex-direction: row;
  }
}